import React from 'react'
import styles from './styles.module.css'

// component function
export default function AccountSubscription(props) {

	const { org } = props

	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	const plan = org.subscriptionPlan
	const calculations = org.subscriptionCalculations

	const subName = plan && plan.name + (plan.id !== 9 ? plan.config.Monthly_billing ? ' - monthly' : ' - yearly' : '') // not trial - append cycle
	const subStart = calculations && new Date(calculations.subStart).toLocaleDateString("en-GB", dateOptions)
	const subRenew = calculations && (calculations.subCancel !== 0 || calculations.subExpired) ? 'n/a' : new Date(calculations.subEnd).toLocaleDateString("en-GB", dateOptions)
	const subRefill = calculations && (calculations.subCancel !== 0 || calculations.subExpired) ? 'n/a' :  new Date(calculations.currentPeriodEnd).toLocaleDateString("en-GB", dateOptions)
	const subExpiration = calculations && (calculations.subCancel !== 0 || calculations.subExpired) && calculations.subEnd > 0 ? new Date(calculations.subEnd).toLocaleDateString("en-GB", dateOptions) : 'n/a'
	const subStatus = calculations ? calculations.subExpired ? 'Expired' : calculations.subCancel !== 0 ? 'Canceled' : 'Active' : ''
	const analysesLeft = plan && (plan.config.Total_videos_month - calculations.currentPeriodAnalysesUsed)
	const extraAnalysesLeft = org.freeAnalyses ? org.freeAnalyses : 0
	const country = org.companyStoreData?.customer?.address?.country
	const taxFactor = country === 'DK' ? .25 : 0
	const priceInclVat = plan ? plan.config.Monthly_billing ? numberWithCommas(plan.config.Monthly_ex_VAT_Monthly_billing * (1+taxFactor)) : numberWithCommas(plan.config.Monthly_ex_VAT_Annual_billing * (1+taxFactor)) : ''

	const cycleName = plan?.config.Calc_cycle_length_month === 12 ? 'year' : 'month'

	const priceExclVat = plan ? plan.config.Monthly_billing ? numberWithCommas(plan.config.Monthly_ex_VAT_Monthly_billing) : numberWithCommas(plan.config.Monthly_ex_VAT_Annual_billing) : ''
	const totalVideosPerCycle = plan?.config.Total_videos_month
	const videoMaxSecs = plan?.config.Max_film_length
	const videoMaxMb = plan?.config.Max_Mb_per_film

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	return (
		<>
			<div className={styles.accountElementBack}>
				<div className="accountElementContainer">
					<h2>Flowsam subscription</h2>
					<div className={styles.subColumnsContainer}>
						<div className={styles.subColumn} style={{flexBasis:'570px'}}>
							<div className={styles.subColumnHeader}>
								<h2 style={{lineHeight:'32px', textAlign:'left'}}>{subName}</h2>
							</div>
							<div className={styles.subColumnTxt} style={{padding:'24px 30px 26px', lineHeight:'22px', display:'flex', justifyContent:'space-between'}}>
								<div style={{marginRight:'30px', marginBottom:'-12px'}}>
									<div className={styles.label}>Subscription start</div>
									<div className={styles.subData}>{subStart}</div>
									<div className={styles.label}>Subscription renewal</div>
									<div className={styles.subData}>{subRenew}</div>
									<div className={styles.label}>Analysis count refill</div>
									<div className={styles.subData}>{subRefill}</div>
								</div>
								<div style={{marginRight:'30px', marginBottom:'-12px'}}>
									<div className={styles.label}>Status</div>
									<div className={styles.subData}>{subStatus}</div>
									<div className={styles.label}>Subscription expiration</div>
									<div className={styles.subData}>{subExpiration}</div>
									<div className={styles.label}>Next payment{calculations?.subCancel === 0 && !calculations?.subExpired && ' (' + priceInclVat + '€)'}</div>
									<div className={styles.subData}>{subRefill}</div>
								</div>
								<div style={{marginRight:'30px', marginBottom:'-12px'}}>
									<div className={styles.label}>Analyses left</div>
									<div className={styles.subData}>{analysesLeft}</div>
									<div className={styles.label}>Extra analyses left</div>
									<div className={styles.subData}>{extraAnalysesLeft}</div>
								</div>
							</div>
						</div>
						<div className={styles.subColumn} style={{flexBasis:'290px'}}>
							<div className={styles.subColumnHeader}>
								<h3 className={styles.boldHeader} style={{textAlign:'left', lineHeight:'22px', marginTop:'4px', padding:'0'}}>Price/{cycleName} <span style={{fontSize:'18px'}}>(ex.&nbsp;VAT)</span></h3>
							</div>
							<div className={styles.subColumnTxt} style={{whiteSpace:'nowrap'}}>
								Video analyses/{cycleName}<br/>
								Max. video length<br/>
								Max. file size per video
							</div>
						</div>
						<div className={styles.subColumn} style={{flexBasis:'170px'}}>
							<div className={styles.subColumnHeader} style={{justifyContent:'center'}}>
								<h2 style={{wordSpacing:'-.1em', padding:'0'}}>{priceExclVat}&nbsp;€</h2>
							</div>
							<div className={styles.subColumnTxt} style={{textAlign:'center'}}>
								{totalVideosPerCycle}<br/>
								{videoMaxSecs} secs<br/>
								{videoMaxMb} MB
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
