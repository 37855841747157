import React from 'react'
import styles from './styles.module.css'

// component function
export default function GetMoreAnalyses() {

	// go to payment page
	function goBuy() {
		window.location.href='https://flowsam.ai/features-plans/'
	}

	return (
		<>
			<div className={styles.sectionHeading}>1. Get more flowsam<sup>®</sup> analyses</div>
			<div className={styles.container}>
				<div className={styles.ctaBoxContainer}>
					<div className={styles.ctaBox}>
						<div className={styles.product}>1 ad-hoc analysis</div>
						<div className={styles.price}>49€</div>
						<div className={styles.ctaBtn} onClick={goBuy}>BUY</div>
					</div>
					<div className={styles.description}>Buy ad-hoc analyses when you need them. Perfect if you perform less than 5 analyses/month.</div>
				</div>
				<div className={styles.ctaBoxContainer}>
					<div className={styles.ctaBox}>
						<div className={styles.product}>Flowsam - unlimited</div>
						<div className={styles.price}>199€/month</div>
						<div className={styles.ctaBtn} onClick={goBuy}>SUBSCRIBE</div>
					</div>
					<div className={styles.description}>Perform as many analyses as you like. Perfect for multiple iterations, cutdowns and A/B tests.</div>
				</div>
			</div>
		</>
	)
}
