import React, { useState, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../../../../utils/globalState'
import { APIContext } from '../../../../utils/api'
import Alert from '../../../common/Alert'
import styles from './styles.module.css'
import { colors } from '../../../../themes/colors'

// component function
export default function AssetBar(props) {

	const { idKey, video, asset, type, hidden } = props
	const { code } = useParams()
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [fullAssetImage, setFullAssetImage] = useState()
	const [showFullImage, setShowFullImage] = useState(false)
	const [croppedAssetImage, setCroppedAssetImage] = useState()
	const [segmentName, setSegmentName] = useState(asset.name)
	const [segmentType, setSegmentType] = useState(asset.cueType)
	const [alertTask, setAlertTask] = useState()
	const context = useContext(APIContext)
	const cueTypes = globalState.cueTypes
	const alertObjectRef = useRef(null)
	const assetImageCached = useRef(false)
	const ownVideo = video?.user?.id === globalState.userData?.id
	const barColor = type === 'brand' ? styles.colorBrand : styles.colorComms
	const score = Math.round(asset.scoreAttention) // TODO: new metric ???
	const assetTypeLabel = cueTypes.find(cueType => cueType.id === asset.cueType).name
	const nameMaxChars = 250

	// alert setup
	const alert = (alertObjectRef.current !== null && alertTask !== undefined &&
		<Alert
			type={alertObjectRef.current.type}
			title={alertObjectRef.current.title}
			message={alertObjectRef.current.message}
			cancelLabel={alertObjectRef.current.cancelLabel}
			actionLabel={alertObjectRef.current.actionLabel}
			waitingLabel={alertObjectRef.current.waitingLabel}
			action={alertTask}
		/>
	)

	// dynamic styles
	const barStyle = {
		width: score + (score < 49 ? 1 : 0) + '%'
	}

	const scoreboxStyle = {
		left: score + '%'
	}

	const bgImageStyle = {
		backgroundImage: 'url(' + croppedAssetImage + ')'
	}

	const fullImageStyle = {
		backgroundImage: 'url(' + fullAssetImage + ')'
	}

	// space bar/arrow key video control override listeners
	useEffect(() => {
		if (globalState.loggedIn) {
			document.getElementById('edit-label-' + idKey)?.addEventListener("keydown", keyOverride)
			document.getElementById('edit-label-' + idKey)?.addEventListener("keyup", keyOverride)
			return () => {
				document.getElementById('edit-label-' + idKey)?.removeEventListener("keydown", keyOverride) // cleanup on unmount
				document.getElementById('edit-label-' + idKey)?.removeEventListener("keyup", keyOverride)
			}
		}
 	}, [idKey, globalState])

	// override video spacebar, arrows and backspace keys when editing segment name
	function keyOverride(e) {
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// get image of asset
	function getAssetImage(asset) {
		const assetObjects = video.objects.filter(obj => obj.segment === asset.id)
		const assetFirstObject = assetObjects.length > 15 ? assetObjects[15] : assetObjects[assetObjects.length-1] // generate thumb a few frames into the segment if possible to compensate for fades
		const assetStartFrame = assetFirstObject.frameNo
		const chunk = Math.floor(assetStartFrame/30)
		const col = assetStartFrame % 5
		const row = Math.floor(assetStartFrame % 30 / 5)
		const image = new Image()
		image.crossOrigin = 'Anonymous'
		image.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000" + chunk).slice(-7) + '.jpg'
		image.onload = e=>cropImage(image, assetFirstObject.x, assetFirstObject.y, assetFirstObject.w, assetFirstObject.h, col, row)
	}

	// crop image
	function cropImage(image, x, y, objwidth, objheight, col, row) {
		assetImageCached.current = true;
		const cropCanvas = document.createElement('canvas')
		const ctx = cropCanvas.getContext('2d')
		ctx.imageSmoothingEnabled = false
		const w = image.width/5  // chunk frame width
		const h = image.height/6 // chunk frame height
		// thumbnail
		cropCanvas.width = objwidth * w * window.devicePixelRatio
		cropCanvas.height = objheight * h * window.devicePixelRatio
		ctx.drawImage(image, col * w + x * w, row * h + y * h, objwidth * w, objheight * h, 0, 0, objwidth * w * window.devicePixelRatio, objheight * h * window.devicePixelRatio)
		setCroppedAssetImage(cropCanvas.toDataURL('image/jpeg'))
		// large image
		cropCanvas.width = w * window.devicePixelRatio
		cropCanvas.height = h * window.devicePixelRatio
		ctx.drawImage(image, col * w, row * h , w, h, 0, 0, w * window.devicePixelRatio, h * window.devicePixelRatio)
		ctx.lineWidth = 2 * window.devicePixelRatio
		ctx.strokeStyle = type === 'brand' ? colors.brand : colors.comms
		ctx.rect(x * w * window.devicePixelRatio, y * h * window.devicePixelRatio, objwidth * w * window.devicePixelRatio, objheight * h * window.devicePixelRatio)
		ctx.stroke()
		setFullAssetImage(cropCanvas.toDataURL('image/jpeg'))
	}

	// update segment title
	function updateSegmentName(e) {
		setSegmentName(e.target.value)
	}

	// save segment title if it changed
	function saveSegmentName(e) {
		if (asset.name !== e.target.value) {
			asset.name = e.target.value
			const updateRequest = {
				segsToUpdate: [asset]
			}
			context.io.socket.post('/api/v1/video/' + video.guid + '?dont_calculate', updateRequest, (data, res) => {
				if (res.statusCode !== 200) {
					// TODO: error handling
				}
			})
		}
	}

	// update segment type - confirm change between brand and comms
	function updateSegmentType(e) {
		const newSegmentType = parseInt(e.target.value)
		const newSegmentCategory = cueTypes.find(cueType => cueType.id === newSegmentType)?.cueTypeCategory
		const oldSegmentCategory = cueTypes.find(cueType => cueType.id === asset.cueType)?.cueTypeCategory
		if (newSegmentCategory !== oldSegmentCategory) { // TODO: if different cueType weights within same cueTypeCategory we must recalculate/reload the report too ********* //
			const newCategoryName = globalState.cueTypeCategories.find(cat => cat.id === newSegmentCategory).name
			const oldCategoryName = globalState.cueTypeCategories.find(cat => cat.id === oldSegmentCategory).name
			brandCommsChangeAlert('If you change an asset’s type from ' + oldCategoryName + ' to ' + newCategoryName + ', flowsam will need to recalculate the ad performance and averages', newSegmentType)
		} else {
			saveSegmentType(newSegmentType)
		}
	}

	// alert if change between brand and comms
	function brandCommsChangeAlert(title, newSegmentType) {
		alertObjectRef.current = {
			type: 'confirm',
			title: title,
			message: 'This may take a while, and the report will reload.\nDo you want to continue?',
			cancelLabel: 'Cancel',
			actionLabel: 'Yes, go ahead',
			waitingLabel: 'Calculating... 0%'
		}
		setAlertTask(()=>(action)=>{ // define alert action and display alert
			if (action) {
				saveSegmentType(newSegmentType, true)
			} else {
				setAlertTask() // remove alert
			}
		})
	}

	// save segment type
	function saveSegmentType(newSegmentType, recalc) {
		asset.cueType = newSegmentType
		const updateRequest = {
			segsToUpdate: [asset]
		}
		context.io.socket.post('/api/v1/video/' + video.guid + (recalc ? '' : '?dont_calculate'), updateRequest, (data, res) => {
			if (res.statusCode === 200) {
				if (recalc) {
					// subscribe to events for this analysis
					context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + video.guid }, (data, res) => {
						if (res.statusCode === 200) {
							const alertBtn = document.getElementById('alert-btn-ok')
							alertBtn.style.minWidth = '190px'
							// listen for analysis recalc progress and reload page when 100%
							context.io.socket.on('video-progress-' + video.guid, msg => {
								alertBtn.innerHTML = 'CALCULATING... ' + msg.done.statisticsCalc + '%'
								if (msg.done.statisticsCalc >= 100) {
									setTimeout(function() { window.location.reload() }, 1000)
								}
							})
						} else {
							// TODO: error handling
						}
					})
				} else {
					setSegmentType(newSegmentType)
				}
			} else {
				// TODO: error handling
			}
		})
	}

	// load asset image if not cached
	video && !assetImageCached.current && getAssetImage(asset)

	const assetImage = video ? <div style={bgImageStyle} className={styles.imageWrapper} title={segmentName} onClick={e=>setShowFullImage(true)} /> : <div className={styles.noImage} />
	const fullImage = video && fullAssetImage && showFullImage ? <div className={styles.overlay} onClick={e=>setShowFullImage(false)}><div style={fullImageStyle} className={styles.fullImage} /></div> : null

	// editable asset label
	const editableLabel = video ? (
		<input
			id={'edit-label-' + idKey}
			className={styles.editableLabel}
			tabIndex={idKey}
			spellCheck="false"
			value={segmentName}
			onChange={updateSegmentName}
			onBlur={saveSegmentName}
			maxLength={nameMaxChars}
			title={segmentName}
			disabled={!globalState.loggedIn || !ownVideo}
		/>
	) : assetTypeLabel

	// asset type select - or label if not logged in
	const unselected = segmentType === cueTypes.find(c => c.defaultForCreationMethod === "GVI-object").id
	const assetTypeMenu = video ? (
		globalState.loggedIn && !code && ownVideo ? (
			<div className={styles.selectWrapper}>
				<select id={'select-asset-type-' + idKey} value={unselected ? '' : segmentType} className={styles.select + ' ' + (unselected && styles.unselected)} onChange={updateSegmentType} title="Asset type">
					<option value="" disabled>Please select</option>
					{globalState.cueTypeCategories.map(cat => (
						<optgroup key={cat.id} label={cat.name}>
							{cueTypes.filter(ctp => ctp.cueTypeCategory === cat.id && ctp.defaultForCreationMethod !== "GVI-object").map(ct => { // omit GVI-object default since they’re not auto-tagged
								return <option key={ct.id} value={ct.id}>{ct.name}</option>
							})}
						</optgroup>
					))}
				</select>
			</div>
		) : unselected ? <div className={styles.unselected}>{assetTypeLabel}</div> : assetTypeLabel
	) : ''

	return (
		<>
			<div style={{display:(hidden ? 'none' : 'flex')}} className={styles.wrapper}>
				<div>{assetImage}</div>
				<div className={styles.col2}>{editableLabel}</div>
				<div className={styles.col3}>
					<div className={styles.barWrapper}>
						<div className={styles.barBack}>
							<div style={barStyle} className={styles.bar + ' ' + barColor} />
						</div>
						<div className={styles.scoreBoxWrapper}>
							<div style={scoreboxStyle} className={styles.scoreBox + ' ' + barColor}>{score}</div>
						</div>
					</div>
					<div className={styles.smallLabel}>100</div>
				</div>
				<div className={styles.col4}>{assetTypeMenu}</div>
				{alert}
			</div>
			{fullImage}
		</>
	)
}
