// asset type array (TMP)
export const industries = [
	{
		id: 1,
		label: 'Retailers',
		subindustries: [
			{
				id: 1,
				label: 'Online marketplaces',
				icon: 'icon-online-marketplaces.svg',
				iconHighlight: 'icon-online-marketplaces-white.svg',
				info: 'e.g. Amazon'
			},
			{
				id: 2,
				label: 'Supermarkets / Hypermarkets',
				icon: 'icon-supermarkets.svg',
				iconHighlight: 'icon-supermarkets-white.svg',
				info: 'e.g. Tesco'
			},
			{
				id: 3,
				label: 'Department stores',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. John Lewis'
			},
			{
				id: 4,
				label: 'Pharmacies/Health & Beauty stores',
				icon: 'icon-pharmacies.svg',
				iconHighlight: 'icon-pharmacies-white.svg',
				info: 'e.g. Superdrug'
			},
			{
				id: 5,
				label: 'Kitchen/Bathroom/Bedroom Design Specialists',
				icon: 'icon-pharmacies.svg',
				iconHighlight: 'icon-pharmacies-white.svg',
				info: ''
			},
			{
				id: 6,
				label: 'Furniture/Home décor stores',
				icon: 'icon-furniture-stores.svg',
				iconHighlight: 'icon-furniture-stores-white.svg',
				info: 'e.g. IKEA'
			},
			{
				id: 7,
				label: 'Hardware/DIY stores & Garden Centres',
				icon: 'icon-hardware-stores.svg',
				iconHighlight: 'icon-hardware-stores-white.svg',
				info: 'e.g. B&Q'
			},
			{
				id: 8,
				label: 'Other Retailers',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 2,
		label: 'Travel & Tourism',
		subindustries: [
			{
				id: 9,
				label: 'Airlines / Train companies',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Lufthansa'
			},
			{
				id: 10,
				label: 'Vehicle Rentals',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Avis'
			},
			{
				id: 11,
				label: 'Taxi / ride sharing',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Uber'
			},
			{
				id: 12,
				label: 'Hotels',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Hilton'
			},
			{
				id: 13,
				label: 'Travel Agents',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Expedia'
			},
			{
				id: 14,
				label: 'Travel Destinations',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Australia'
			},
			{
				id: 15,
				label: 'Other Travel & Tourism',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 3,
		label: 'Clothing & Fashion',
		subindustries: [
			{
				id: 16,
				label: 'Non-designer',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Next, Sokonda'
			},
			{
				id: 17,
				label: 'Designer/luxury',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Gucci, Rolex'
			},
			{
				id: 18,
				label: 'Other Clothing & Fashion',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 4,
		label: 'Sportswear & Sports equipment',
		subindustries: [
			{
				id: 19,
				label: 'Sportswear',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Nike, Patagonia'
			},
			{
				id: 20,
				label: 'Sports equipment',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Salomon'
			},
			{
				id: 21,
				label: 'Other Sportswear & Sports equipment',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 5,
		label: 'News & Entertainment',
		subindustries: [
			{
				id: 22,
				label: 'Movies & TV',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Netflix, BBC, Disney+'
			},
			{
				id: 23,
				label: 'Events & Shows',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 24,
				label: 'Music & books',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 25,
				label: 'Video games & platforms',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 26,
				label: 'Newspapers & Magazines',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 27,
				label: 'Other News & Entertainment',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 6,
		label: 'Food & Drink',
		subindustries: [
			{
				id: 28,
				label: 'Alcoholic drinks',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Beer, Wine, Spirits (e.g. Heineken)'
			},
			{
				id: 29,
				label: 'Non-alcoholic drinks',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Soft drinks, Juices, Water, Coffee/Tea (e.g. Coke)'
			},
			{
				id: 30,
				label: 'Foods',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Fresh produce, Dairy, Frozen Foods, Other Foods (e.g. Bird’s Eye)'
			},
			{
				id: 31,
				label: 'Snacks / confectionery',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Doritos'
			},
			{
				id: 32,
				label: 'Bars/Restaurants & Fast Food brands',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. KFC'
			},
			{
				id: 33,
				label: 'Food delivery services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. JustEat'
			},
			{
				id: 34,
				label: 'Other Food & Drink',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 7,
		label: 'Home & Family',
		subindustries: [
			{
				id: 35,
				label: 'Household products',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Ariel'
			},
			{
				id: 36,
				label: 'Toys and hobbies',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. LEGO'
			},
			{
				id: 37,
				label: 'Furniture brands',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Ercol'
			},
			{
				id: 38,
				label: 'Homeware brands',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Royal Copenhagen'
			},
			{
				id: 39,
				label: 'Home decor brands',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Dulux'
			},
			{
				id: 40,
				label: 'Other Home & Family',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 8,
		label: 'Health & personal care',
		subindustries: [
			{
				id: 41,
				label: 'Personal care',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Cleansing & hygiene (e.g. Colgate, Gilette)'
			},
			{
				id: 42,
				label: 'Pharmaceutical brands (over-the-counter drugs)',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Nurofen'
			},
			{
				id: 43,
				label: 'Pharmaceutical brands (Prescription drugs) ',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Wegovy'
			},
			{
				id: 44,
				label: 'Other Health & personal care',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 9,
		label: 'Beauty',
		subindustries: [
			{
				id: 45,
				label: 'Beauty & cosmetics',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. MaxFactor'
			},
			{
				id: 46,
				label: 'Fragrances',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Chanel, Old Spice'
			},
			{
				id: 47,
				label: 'Other Beauty',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 10,
		label: 'Automotive',
		subindustries: [
			{
				id: 48,
				label: 'Vehicles',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Cars, Trucks, Motorcycles (e.g. Volvo)'
			},
			{
				id: 49,
				label: 'Automotive Parts',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Tyres, Batteries, Brakes (e.g. Michelin)'
			},
			{
				id: 50,
				label: 'Automotive Services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Breakdown services, Vehicle Repair/ Maintenance (e.g. the AA)'
			},
			{
				id: 51,
				label: 'Other Automotive',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 11,
		label: 'Technology and Appliances',
		subindustries: [
			{
				id: 52,
				label: 'Software (incl. cloud)',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Microsoft / Salesforce'
			},
			{
				id: 53,
				label: 'PCs/Laptops',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Dell, Apple MacBook'
			},
			{
				id: 54,
				label: 'Personal electronics',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Smartphones, Tablets, Wearables, E-shaver (e.g. Philips)'
			},
			{
				id: 55,
				label: 'Home appliances',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'TVs, Audio systems, Smart home devices, Kitchen appliances, Home/garden tools/appliances (e.g. Philips, Bosch)'
			},
			{
				id: 56,
				label: 'Other Technology and Appliances',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 12,
		label: 'Services',
		subindustries: [
			{
				id: 57,
				label: 'Educational Institutes/Services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Universities'
			},
			{
				id: 58,
				label: 'Telecoms & Internet Services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Vodafone'
			},
			{
				id: 59,
				label: 'Financial Services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Retail Banking, Investment Banking, Loans/Credit, Insurance'
			},
			{
				id: 60,
				label: 'Utilities Providers',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. British Gas, Andel'
			},
			{
				id: 61,
				label: 'Business Services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'Lawyers, Architects, Consultants, Agencies (e.g. PWC)'
			},
			{
				id: 62,
				label: 'Tradespeople/companies',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'e.g. Plumbers'
			},
			{
				id: 63,
				label: 'Other Services',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	},
	{
		id: 13,
		label: 'Industrial',
		subindustries: [
			{
				id: 64,
				label: 'Energy infrastructure',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 65,
				label: 'Manufacturing',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 66,
				label: 'Construction',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 67,
				label: 'Materials',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 68,
				label: 'Mining and Metals',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 69,
				label: 'Textiles',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 70,
				label: 'Transportation and logistics',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 71,
				label: 'Industrial engineering',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: ''
			},
			{
				id: 72,
				label: 'Other Industrial',
				icon: 'icon-department-stores.svg',
				iconHighlight: 'icon-department-stores-white.svg',
				info: 'If your ad’s industry doesn’t match any of the above categories'
			}
		]
	}
]

// get main industry by subindustry id
export function getIndustryBySubIndustryId(id) {
	return industries.find(ind => ind.subindustries.find(sub => sub.id === id) !== undefined)
}

// get subindustry by id
export function getSubindustryById(id) {
	return getIndustryBySubIndustryId(id).subindustries?.find(sub => sub.id === id)
}