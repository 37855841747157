import React, { useState, useRef, useEffect, useContext } from 'react'
import { Tour } from 'antd'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { industries, getIndustryBySubIndustryId } from '../../utils/industries'
import { assetTypes } from '../../utils/assetTypes'
import Sidebar from '../../components/Sidebar'
import NewAnalysis from '../../components/dashboard/NewAnalysis'
import LatestAnalysis from '../../components/dashboard/LatestAnalysis'
import Todo from '../../components/dashboard/Todo'
import TopThree from '../../components/dashboard/TopThree'
import Benchmarks from '../../components/dashboard/Benchmarks'
import AnalysisCount from '../../components/dashboard/AnalysisCount'
import SystemStatus from '../../components/dashboard/SystemStatus'
import Guides from '../../components/dashboard/Guides'
import News from '../../components/dashboard/News'
import styles from './styles.module.css'

// component function
export default function Dashboard() {

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [analyses, setAnalyses] = useState()
	const [analysesFiltered, setAnalysesFiltered] = useState()
	const industryFilterRef = useRef()
	const yearFilterRef = useRef()
	const mediaTypeFilterRef = useRef()

	// Tour
	const ref1 = useRef(null)
	const ref2 = useRef(null)
	const ref3 = useRef(null)
	const ref4 = useRef(null)
	const ref5 = useRef(null)
	const ref6 = useRef(null)
	const ref7 = useRef(null)
	const [open, setOpen] = useState(false)

	const steps = [
		{
			title: 'Welcome to flowsam',
			description: 'This is your dashboard with easy access to your latest analyses, statistics and useful guides. Continue to learn more.',
		},
		{
			title: 'New analysis',
			description: 'Drop a video or an image here to get started with a new flowsam analysis. You can also click the box to select a file.',
			target: () => ref1.current
		},
		{
			title: 'Latest analysis',
			description: 'Your latest uploaded video or image where you have completed brand cue tagging. Click to get directly to the report.',
			target: () => ref2.current
		},
		{
			title: 'Todo',
			description: 'Latest analyses that have not yet been finalized. Clik on one to continue brand cue tagging. You can also find all unfinished analyses under "My analyses" in the side bar.',
			target: () => ref3.current
		},
		{
			title: 'Top 3',
			description: 'Your best performing analyses by Visual Clarity, Brand Attention and Communication attention. Click one to get to the report. The list can be filtered by Industry, Year and Asset type.',
			target: () => ref4.current
		},
		{
			title: 'Benchmarks',
			description: 'See how your own average scores compare to Industry or your Competitor analyses. Can be filtered by Industry, Year and Asset type.',
			target: () => ref5.current
		},
		{
			title: 'Analysis count',
			description: 'Statistics on how many analyses you have made per month. Can be filtered by Industry, Year and Asset type.',
			target: () => ref6.current
		},
		{
			title: 'Knowledge base',
			description: 'A good place to start if you need help with flowsam, or just want to know more about the fine art of attention prediction and distinctive brand assets.',
			target: () => ref7.current,
			nextButtonProps: {children:(<span>&nbsp;OK&nbsp;</span>)}
		},
	]

	// check if dashboard tour is done - if not, open Tour dialogue
	useEffect(() => {
		const settings = globalState.userData?.settings
		const permission = globalState.userData?.permissionType1
		settings !== undefined && !settings.dashboardTourDone && permission === '1' && setOpen(true)
	}, [globalState.userData])

	// get user’s analysis list
	useEffect(() => {
		context.io.socket.get('/api/v1/video', (data, res) => {
			if (res.statusCode === 200) {
				setAnalyses(data)
				setAnalysesFiltered(data)
				industryFilterRef.current = document.getElementById('industryFilter')
				yearFilterRef.current = document.getElementById('yearFilter')
				mediaTypeFilterRef.current = document.getElementById('mediaTypeFilter')
				adjustSelectWidth(industryFilterRef.current)
				adjustSelectWidth(yearFilterRef.current)
				adjustSelectWidth(mediaTypeFilterRef.current)
			} else {
				// TODO: error handling
			}
		})
	}, [context.io.socket])

	// filter top 3 on selected industry and media type
	function filterOnIndustryYearMediaType() {
		const iSel= industryFilterRef.current
		const ySel= yearFilterRef.current
		const mSel = mediaTypeFilterRef.current
		const industries = iSel.value ? analyses.filter(o => getIndustryBySubIndustryId(o.industrySubCode)?.id === parseInt(iSel.value)) : analyses
		const industriesYears = ySel.value ? industries.filter(o => new Date(o.createdAt).getFullYear() === parseInt(ySel.value)) : industries
		const industriesYearsTypes = mSel.value ? industriesYears.filter(o => o.targetPlatform === parseInt(mSel.value)) : industriesYears
		setAnalysesFiltered(industriesYearsTypes)
		adjustSelectWidth(iSel)
		adjustSelectWidth(ySel)
		adjustSelectWidth(mSel)
	}

	// adapt select menu width to fit selected text
	function adjustSelectWidth(sel) {
		const measure = document.getElementById('measureWidth')
		measure.innerHTML = sel.options[sel.selectedIndex].text
		sel.style.width = measure.offsetWidth + 25.5 + 'px'
	}
	
	// save dashboard Tour done in user settings if not already saved
	function saveDashboardTourDone() {
		if (!globalState.userData?.settings?.dashboardTourDone) {
			const settings = { ...globalState.userData?.settings, dashboardTourDone:true }
			context.io.socket.patch('/api/v1/user', { settings:settings }, (data, res) => { // save dashboardTourDone flag in user settings
				if (res.statusCode === 200) {
					setGlobalState({ ...globalState, userData:{ ...data, settings:settings } })
				} else {
					// TODO: error handling
				}
			})
		}
		setOpen(false)
	}

	// find and sort industry codes and analysis types in FINISHED analyses
	const usedIndustryCodes = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished').map(a => a.industrySubCode).sort((a, b) => (a - b)))]
	const usedYears = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished').map(a => new Date(a.createdAt).getFullYear()).sort((a, b) => (b - a)))] // years desc
	const usedVideoAssetTypes = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished' && a.analysisType < 3).map(a => a.targetPlatform).sort((a, b) => (a - b)))] // find used video types
	const usedImageAssetTypes = [...new Set(analyses?.filter(a=> a.frontendState?.status === 'finished' && a.analysisType === 3).map(a => a.targetPlatform).sort((a, b) => (a - b)))] // find used video types

 	// PLACEHOLDER DATA //TODO: get real benchmark scores (filtered by Industry, year and analysis type)
 	const benchmarksFiltered = {
		clarity: {
			my: 65,
			ind: 57,
			comp: null
		},
		brand: {
			my: 58,
			ind: 53,
			comp: null
		},
		comms: {
			my: 37,
			ind: 42,
			comp: null
		},
	}

	return (
		<>
			<Sidebar />
			<div className={styles.wrapper}>
				<h2>Dashboard
					<div style={{display: 'inline-block'}}>
						<div className={styles.infoButton} onClick={()=>setOpen(true)}>?</div>
					</div>
				</h2>
				<h3>Analyses</h3>
				<div className={styles.cardGroup}>
					<NewAnalysis tourRef={ref1} />
					<LatestAnalysis analyses={analyses} tourRef={ref2} />
					<Todo analyses={analyses} tourRef={ref3} />
				</div>
				<div className={styles.headingGroup}>
					<div className={styles.industryYearFilter}>
						{analyses && (
							<div className={styles.industryYearWrapper}>
								<div className={styles.selectWrapper}>
									<select id="industryFilter" ref={industryFilterRef} className={styles.select} title="Filter on industry" onChange={filterOnIndustryYearMediaType}>
										<option value=''>All industries</option>
										{industries.filter(ind => ind.subindustries.find(sub => usedIndustryCodes.indexOf(sub.id) > -1)).map((ind,i) => {
											return(<option key={'industry-'+i} value={ind.id}>{ind.label}</option>)
										})}
									</select>
								</div>
								<div className={styles.selectWrapper}>
									<select id="yearFilter" ref={yearFilterRef} className={styles.select} title="Filter on year" onChange={filterOnIndustryYearMediaType}>
										<option value=''>All years</option>
										{usedYears.map((i) => {
											return(<option key={'year-'+i} value={i}>{i}</option>)
										})}
									</select>
								</div>
							</div>
						)}
					</div>
					<h3>Analysis insights</h3>
					<div className={styles.mediaTypeFilter}>
						{analyses && (
							<div className={styles.selectWrapper}>
								<select id="mediaTypeFilter" ref={mediaTypeFilterRef} className={styles.select} title="Filter on media type" onChange={filterOnIndustryYearMediaType}>
									<option value=''>All asset types</option>
									{usedVideoAssetTypes.length > 0 && <optgroup label="Video">
										{assetTypes.filter(at => usedVideoAssetTypes.indexOf(at.id) > -1).map((o,i) => {
											return(<option key={'videoassettype-'+i} value={o.id}>{o.label}</option>)
										})}
									</optgroup>}
									{usedImageAssetTypes.length > 0 && <optgroup label="Image">
										{assetTypes.filter(at => usedImageAssetTypes.indexOf(at.id) > -1).map((o,i) => {
											return(<option key={'imageassettype-'+i} value={o.id}>{o.label}</option>)
										})}
									</optgroup>}
								</select>
							</div>
						)}
					</div>
					<div id='measureWidth' className={styles.selectWidth} />
				</div>
				<div className={styles.cardGroup}>
					<div ref={ref4} className={styles.cardGroupTour}>
						<TopThree analyses={analysesFiltered} type='clarity' />
						<TopThree analyses={analysesFiltered} type='brand' />
						<TopThree analyses={analysesFiltered} type='comms' />
					</div>
				</div>
				<div className={styles.cardGroup}>
					<Benchmarks benchmarks={benchmarksFiltered} tourRef={ref5} />
					<AnalysisCount analyses={analysesFiltered} tourRef={ref6} />
				</div>
				<h3>Knowledge base</h3>
				<div className={styles.cardGroup}>
					<div ref={ref7} className={styles.cardGroupTour}>
						<SystemStatus status={1} />
						<Guides />
						<News />
					</div>
				</div>
				<Tour open={open} steps={steps} zIndex={10001} onClose={saveDashboardTourDone}/>
			</div>
		</>
	)
}
