import React from 'react'
import styles from './styles.module.css'

// component function
export default function NotFound() {
	return (
		<div className={styles.container}>
			<h2>404 - Not found</h2>
		</div>
	)
}
