import React, { useState, useContext, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'
import Sidebar from '../../components/Sidebar'
import ReportHeader from '../../components/report/ReportHeader'
import ReportVideo from '../../components/report/ReportVideo'
import ReportSummary from '../../components/report/ReportSummary'
import ReportDetails from '../../components/report/ReportDetails'
import ReportAssets from '../../components/report/ReportAssets'
import styles from './styles.module.css'

// component function
export default function Report() {

	const context = useContext(APIContext)
	const [video, setVideo] = useState()
	const [forceUpdate, setForceUpdate] = useState(0)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const history = useHistory()
	const { guid, code } = useParams()
	const globalStateRef = useRef(globalState)

	globalStateRef.current = globalState

	// get video data (and norms if shared report) - can’t use useEffect here since globalState is not set when invoked
	if (!globalState.cueTypes) {
		setTimeout(function() {setForceUpdate(forceUpdate+1)}, 200) // wait for globalState being updated with cueTypes
	} else if (!video) {
		const path = code ? '/api/v1/video/shared/' + guid + '/' + code : '/api/v1/organization/video/' + guid
		context.io.socket.get(path, (videodata, res) => {
			if (res.statusCode === 200) {
				setVideo(videodata)
				// update organization in globalState with company avatar if not logged in
				!globalStateRef.current.loggedIn && videodata.organizationAvatar && setGlobalState({ ...globalStateRef.current, loggedIn:false, userData:{organization:{settings:{avatar:videodata.organizationAvatar}}}})
			} else if (res.statusCode === 404) { // shared report not found
				history.push('/notfound')
			} else {
				// TODO: error handling
			}
		})
	}

	// report content
	const content = (
		// not logged in and not shared report
		!globalState.loggedIn && !code ?
			null
		:
		// no video data received yet
		!video ?
			<div className={styles.container}>
				<BeatLoader color={colors.background3} />
			</div>
		:
		// no video in received data
		!video.guid ?
			<div className={styles.container}>
				<h3 style={{color:colors.text}}>Report not found!</h3>
			</div>
		:
		// video is being edited - show message
		video.frontendState?.status === 'work-in-progress' ?
			<div className={styles.container}>
				<h3 style={{marginBottom:'10px'}}>The report is currently being edited...</h3>
				<h4>Please try again later.</h4>
			</div>
		:
		// video received - show report content
		<>
			<ReportHeader video={video} />
			<ReportVideo video={video} />
			<ReportSummary video={video} />
			{video?.analysisType !== 3 && <ReportDetails video={video} />} {/* hide details if still image */}
			<ReportAssets video={video} />
		</>
	)

	return (
		<>
			<Sidebar isReport={true} video={video} />
			<div className={styles.wrapper}>
				{content}
			</div>
		</>
	)
}
