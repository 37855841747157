import React, { useContext } from 'react'
import { GlobalContext } from '../../utils/globalState'
import Sidebar from '../../components/Sidebar'
import AnalysisList from '../../components/analysis-list/AnalysisList'
import styles from './styles.module.css'

// component function
export default function MyAnalyses() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars

	// step content
	const content = (
		// not logged in
		!globalState.loggedIn ?
			null
		:
		// logged in - show list
		<AnalysisList />
	)

	return (
		<>
			<Sidebar />
			<div className={styles.wrapper}>
				{content}
			</div>
		</>
	)
}
