import React, { useState, useEffect, useRef, useContext } from 'react'
import { BeatLoader } from 'react-spinners'
import { GlobalContext } from '../../../../utils/globalState'
import { colors } from '../../../../themes/colors'
import useLocalStorage from '../../../../utils/useLocalStorage'
import DetailsGraph from '../DetailsGraph'
import videoPlay from '../../../../assets/images/video-play.svg'
import videoPause from '../../../../assets/images/video-pause.svg'
import styles from './styles.module.css'

// component function
export default function DetailsVideo(props) {

	const { video, type } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [forceUpdate, setForceUpdate] = useState(0)
	const [reportSettings, setReportSettings] = useLocalStorage('reportGraph' + type + 'Settings', { showClarity:true, showBrand:true, showComms: true })
	const chunksLoadedRef = useRef(false)
	const chunksLoadingRef = useRef(false)
	const reqAnimIdRef = useRef()
	const isPlayingRef = useRef(false)
	const frameRef = useRef(0)
	const displayFrameNoRef = useRef(false)
	const chunkArrayRef = useRef([])
	const canvasRef = useRef()
	const ctxRef = useRef()
	const videoContainerRef = useRef()
	const controlsContainerRef = useRef()
	const controlsRef = useRef()
	const vtimeRef = useRef()
	const vcontrolRef = useRef()
	const clarityScoreRef = useRef()
	const frameScoreClarityRef = useRef()
	const brandScoreRef = useRef()
	const frameScoreBrandRef = useRef()
	const commsScoreRef = useRef()
	const frameScoreCommsRef = useRef()
	const clarityMarkerRef = useRef()
	const brandMarkerRef = useRef()
	const commsMarkerRef = useRef()
	const maxWidth = 422
	const maxHeight = 237
	const videoRatio = video ? video.width / (video.height *  (1/video.streamInfo.pixelAspectRatio)) : 1.78
	const videoWidth = videoRatio < 1 ? maxHeight * videoRatio : maxWidth
	const videoHeight = videoRatio < 1 ? maxHeight : videoWidth * 1/videoRatio
	const canvasWidth = videoWidth * window.devicePixelRatio
	const canvasHeight = videoHeight * window.devicePixelRatio
	const interval = 1000/video.frameRate
	const isMobile = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0)

	let then = Date.now()
	let mouseDownX = 0
	let lastMouseX = 0
	let draggerX = 0
	let numChunksLoaded = 0
	let spacePressed = false
	
	// dynamic styles
	const canvasStyle = {
		maxWidth: videoWidth
	}

	// context- & canvas refs & other inits on mount
	useEffect(() => {
		// video canvas init
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = false
		// space bar video control
		window.addEventListener('keydown', keyPressed)
		window.addEventListener('keyup', keyReleased)
		// cleanup on unmount
		return () => {
			cancelAnimationFrame(reqAnimIdRef.current)
			window.removeEventListener("keydown", keyPressed)
			window.removeEventListener("keyup", keyReleased)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// render video frame on component updates
	useEffect(() => {
		renderFrame(frameRef.current)
 	})

	// enable video controls if more than half of the video is visible and the video is closest to window center
	function isInViewport(elem) {
		let canPlay = false
		const elemBox = elem.getBoundingClientRect()
		const windowHeight = window.innerHeight || document.documentElement.clientHeight
		const videoMid = elemBox.top + elemBox.height/2
		const maxDistFromMid = (660 - elemBox.height)/2 + elemBox.height/2
		const distFromMid = windowHeight/2 - videoMid
		if (type === 'average') {
			canPlay = videoMid > 0 && videoMid < windowHeight && distFromMid < maxDistFromMid
		} else {
			canPlay = videoMid > 0 && videoMid < windowHeight && distFromMid > -maxDistFromMid
		}
		return canPlay
	}

	// mouse down on player control - start drag (or click)
	function startDrag(e) {
		if (!chunksLoadedRef.current) { // prevent drag/play while preloading
			return
		}
		const controlsWidth = controlsContainerRef.current.clientWidth
		mouseDownX = lastMouseX = isMobile ? e.touches[0].clientX : e.clientX
		draggerX = parseFloat(controlsRef.current.style.left, 10)/100 * controlsWidth
		window.addEventListener(isMobile ? "touchmove" : "mousemove", dragController)
		window.addEventListener(isMobile ? "touchend" : "mouseup", stopDrag)
		isPlayingRef.current && pauseVideo(true)
	}

	// mouse up on player control - stop drag (or end click)
	function stopDrag() {
		cancelAnimationFrame(reqAnimIdRef.current)
		window.removeEventListener(isMobile ? "touchmove" : "mousemove", dragController)
		window.removeEventListener(isMobile ? "touchend" : "mouseup", stopDrag)
		if (mouseDownX !== lastMouseX) {
			isPlayingRef.current = true
		}
		toggleVideoPlay()
	}

	// drag controller
	function dragController(e) {
		lastMouseX = isMobile ? e.touches[0].clientX : e.clientX
		reqAnimIdRef.current = requestAnimationFrame(updateController)
	}

	// update controller position
	function updateController() {
		const controlsWidth = controlsContainerRef.current.clientWidth
		let dist = lastMouseX - mouseDownX
		dist = dist < -draggerX ? -draggerX : dist > controlsWidth-draggerX ? controlsWidth-draggerX : dist
		const leftPos = draggerX + dist
		frameRef.current = Math.round(leftPos/controlsWidth * (video.frameCount-3))
		renderFrame(frameRef.current)
	}

	// toggle video play/pause
	function toggleVideoPlay() {
		cancelAnimationFrame(reqAnimIdRef.current)
		isPlayingRef.current = !isPlayingRef.current
		if (isPlayingRef.current) {
			reqAnimIdRef.current = requestAnimationFrame(playFrame)
		} else {
			renderFrame(frameRef.current)
		}
		vcontrolRef.current.style.backgroundImage = 'url(' + (isPlayingRef.current ? videoPause : videoPlay) + ')'
	}

	// video play frame loop
	function playFrame() {
		reqAnimIdRef.current = requestAnimationFrame(playFrame)
		const now = Date.now()
		const delta = now - then
		if (delta > interval) { // check if enough time has elapsed to execute
			then = now - delta % interval
			++frameRef.current === video.frameCount-3 && pauseVideo() // video end - skipped last frame since it hasn’t any objects
			frameRef.current = frameRef.current > video.frameCount-3 ? 0 : frameRef.current
			renderFrame(frameRef.current)
		}
	}

	// pause video
	function pauseVideo(keepPlayState=false) {
		cancelAnimationFrame(reqAnimIdRef.current)
		if (!keepPlayState) {
			isPlayingRef.current = false
		}
		if (vcontrolRef.current) {
			vcontrolRef.current.style.backgroundImage = 'url(' + videoPlay + ')'
		}
	}

	// jump to frame when clicked/tapped on timeline
	function jumpToFrame(e) {
		const clientX = isMobile ? e.touches[0].clientX : e.clientX
		if (clientX - e.currentTarget.getBoundingClientRect().x - 15 > e.currentTarget.getBoundingClientRect().width-15) return
		let gotoFrame = Math.round((clientX - e.currentTarget.getBoundingClientRect().x - 15)/(e.currentTarget.getBoundingClientRect().width-30) * (video.frameCount-3))
		if (gotoFrame < 0) gotoFrame = 0
		if (gotoFrame > video.frameCount-3) gotoFrame =  video.frameCount-3
		frameRef.current = gotoFrame
		isPlayingRef.current === true && pauseVideo()
		renderFrame(frameRef.current)
	}

	// handle key events
	function keyPressed(e) {
		if (spacePressed) {
			e.preventDefault()
		} else if (e.keyCode === 32) { // spacebar 
			e.preventDefault()
			if (!chunksLoadedRef.current) { // prevent keypress play while preloading
				return
			}
			if (isInViewport(canvasRef.current)) {
				spacePressed = true
				toggleVideoPlay()
			}
		} else if (e.keyCode === 37 && isInViewport(canvasRef.current)) { // left arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current > 0 && renderFrame(--frameRef.current)
		} else if (e.keyCode === 39 && isInViewport(canvasRef.current)) { // right arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current < video.frameCount-3 && renderFrame(++frameRef.current)
		}
	}

	// cancel spacebar pressed flag
	function keyReleased(e) {
		if (e.keyCode === 32) {
			spacePressed = false
		}
	}

	// cut frame image from chunk and paint it on canvas, draw objects, annotations and update controls/values
	function renderFrame(rframe) {
		if (!chunksLoadedRef.current || !Number.isInteger(rframe)) { // prevent rendering while preloading or if frame is not int
			return
		}
		const ctx = ctxRef.current
		const chunk = Math.floor(rframe/30)
		const col = rframe % 5
		const row = Math.floor(rframe % 30 / 5)
		const w = chunkArrayRef.current[0].width/5
		const h = chunkArrayRef.current[0].height/6
		const selChunk = chunkArrayRef.current[chunk]
		ctx.drawImage(selChunk, w*col, h*row, w, h, 0, 0, canvasWidth, canvasHeight)

		// controls, score & time - showing minutes and hours as necessary on video
		if (vtimeRef.current && controlsRef.current) {
			if (displayFrameNoRef.current) {
				vtimeRef.current.innerHTML = 'frame ' + (frameRef.current + 1)
			} else {
				const secs = rframe/video.frameRate
				const timestring = new Date(secs * 1000).toISOString()
				vtimeRef.current.innerHTML = secs > 3600 ? timestring.substring(12, 22) : secs > 600 ? timestring.substring(14, 22) : timestring.substring(15, 22)
			}
			// position video player control knob and time container
			const playedPct = rframe/(video.frameCount-3) * 100
			controlsRef.current.style.left = playedPct + '%'
			// score metric label placement
			const leftPos = controlsRef.current.getBoundingClientRect().left - controlsContainerRef.current.getBoundingClientRect().left;
			const controlsWidth = controlsContainerRef.current.clientWidth
			const dir = leftPos > controlsWidth - 115 ? 'row-reverse' : 'row'
			if (reportSettings.showClarity && clarityScoreRef.current) {
				const scoreClarity = type === 'average' ? video?.frames[frameRef.current].scoreClarity : video?.frames[frameRef.current].thisFrameScoreClarity // scoreClarityWindowAvg   thisFrameScoreClarityWindowAvg
				clarityScoreRef.current.style.flexDirection = dir
				frameScoreClarityRef.current.innerHTML = Math.round(scoreClarity)
				if (clarityMarkerRef.current) {
					clarityMarkerRef.current.style.top = 57.5 + 111 - 111/100 * scoreClarity + 'px'
				}
			}
			if (reportSettings.showBrand && brandScoreRef.current) {
				const scoreBrand = type === 'average' ? frameRef.current < video.frameCount-3 ? video?.frames[frameRef.current].scoreCueTypeCategoryAttention[1] : video?.scoreCueTypeCategoryAttention[1] : video?.frames[frameRef.current].thisFrameScoreCueTypeCategoryAttention[1] // if average and last frame - use total video score
				brandScoreRef.current.style.flexDirection = dir
				frameScoreBrandRef.current.innerHTML = Math.round(scoreBrand)
				if (brandMarkerRef.current) {
					brandMarkerRef.current.style.top = 57.5 + 111 - 111/100 * scoreBrand + 'px'
				}
			}
			if (reportSettings.showComms && commsScoreRef.current) {
				const scoreComms = type === 'average' ? frameRef.current < video.frameCount-3 ? video?.frames[frameRef.current].scoreCueTypeCategoryAttention[2] : video?.scoreCueTypeCategoryAttention[2] : video?.frames[frameRef.current].thisFrameScoreCueTypeCategoryAttention[2] // if average and last frame - use total video score
				commsScoreRef.current.style.flexDirection = dir
				frameScoreCommsRef.current.innerHTML = Math.round(scoreComms)
				if (commsMarkerRef.current) {
					commsMarkerRef.current.style.top = 57.5 + 111 - 111/100 * scoreComms + 'px'
				}
			}
		}
	}

	// load image chunks (but wait until chunks are cached by ReportVideo component)
	if (video && !chunksLoadingRef.current && !chunksLoadedRef.current && globalState.reportVideoCached) {
		chunksLoadingRef.current = true;
		const tmpImg = new Image()
		tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
		tmpImg.onload = checkLoaded
		chunkArrayRef.current.push(tmpImg)
	}

	// check that all chunks are loaded
	function checkLoaded(e) {
		e.target.onload = null
		numChunksLoaded++
		if (numChunksLoaded === video.chunks) {
			chunksLoadedRef.current = true
			setForceUpdate(forceUpdate+1)
		}

		if (chunkArrayRef.current.length < video.chunks) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
	}

	// toggle time/frameNo display
	function toggleFrameNoDisplay() {
		displayFrameNoRef.current = !displayFrameNoRef.current
		renderFrame(frameRef.current)
	}

	// canvas
	const canvas = <canvas style={canvasStyle} className={styles.canvas} ref={canvasRef} width={canvasWidth} height={canvasHeight} onTouchStart={startDrag} onMouseDown={isMobile ? null : startDrag}/>

	// preloader
	const preLoader = (!chunksLoadedRef.current &&
		<div className={styles.loaderContainer}>
			<BeatLoader color={colors.background3} />
		</div>
	)

	const clarityScoreBox = (reportSettings.showClarity &&
		<div ref={clarityScoreRef} className={styles.scoreWrapper}>
			<div ref={frameScoreClarityRef} className={styles.scoreBox + ' ' + styles.colorClarity}>0</div>
			<div className={styles.scoreLabel}>Visual Clarity</div>
		</div>
	)

	const brandScoreBox = (reportSettings.showBrand &&
		<div ref={brandScoreRef} className={styles.scoreWrapper}>
			<div ref={frameScoreBrandRef} className={styles.scoreBox + ' ' + styles.colorBrand}>0</div>
			<div className={styles.scoreLabel}>Brand Attention</div>
		</div>
	)

	const commsScoreBox = (reportSettings.showComms &&
		<div ref={commsScoreRef} className={styles.scoreWrapper}>
			<div ref={frameScoreCommsRef} className={styles.scoreBox + ' ' + styles.colorComms}>0</div>
			<div className={styles.scoreLabel}>Comm. Attention</div>
		</div>
	)
	
	const scoreMarkers = (
		<>
			{reportSettings.showClarity && chunksLoadedRef.current && <div ref={clarityMarkerRef} className={styles.scoreMarker + ' ' + styles.colorClarity} />}
			{reportSettings.showBrand && chunksLoadedRef.current && <div ref={brandMarkerRef} className={styles.scoreMarker + ' ' + styles.colorBrand} />}
			{reportSettings.showComms && chunksLoadedRef.current && <div ref={commsMarkerRef} className={styles.scoreMarker + ' ' + styles.colorComms} />}
		</>
	)

	// video controls
	const controls = (
		<>
			<div className={styles.lineContainer} onTouchStart={jumpToFrame} onMouseDown={isMobile ? null : jumpToFrame}>
				<div className={styles.line} />
			</div>
			<div ref={controlsRef} className={styles.draggerContainer}>
				<div ref={vtimeRef} className={styles.time} onClick={toggleFrameNoDisplay}>0:00.00</div>
				<div ref={vcontrolRef} className={styles.dragger} onTouchStart={startDrag} onMouseDown={isMobile ? null : startDrag} />
				{clarityScoreBox}
				{brandScoreBox}
				{commsScoreBox}
				{scoreMarkers}
			</div>
		</>
	)

	const title = (type === 'average' ?
		'Ad performance average at different view-time durations' :
		'Ad performance frame-by-frame'
	)

	const description = (type === 'average' ?
		'The graph shows how your ad will perform depending on how long people watch it for. Remember - people don’t often watch ads from start to finish, so you need to know how your ad will perform with both shorter and longer view-times.' :
		'This graph shows which moments (frames) are contributing most to your ad’s performance. Some scenes are less important because they fail to gain attention, don’t highlight the brand or don’t convey the message. To improve your ad, consider editing out weak scenes that detract from effectiveness and focus more on scenes that generate high attention, highlight the brand or convey the desired message.'
	)

	return (
		<>
			<div className={styles.textVideoWrapper}>
				<div className={styles.textWrapper}>
					<div className={styles.title}>{title}</div>
					<div className={styles.description}>{description}</div>
				</div>
				<div ref={videoContainerRef} className={styles.videoWrapper}>
					{canvas}
					{preLoader}
				</div>
			</div>
			<div ref={controlsContainerRef} className={styles.controlsContainer}>
				{controls}
			</div>
			<div className={styles.graphContainer}>
				<DetailsGraph video={video} type={type} reportSettings={reportSettings} setReportSettings={setReportSettings} />
			</div>
		</>	
	)
}
