import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import styles from './styles.module.css'

// component function
export default function Login(props) {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const context = useContext(APIContext)
	const history = useHistory()

	// log in
	function login(e) {
		e.preventDefault()
		context.io.socket.post('/api/v1/user/login?recaptcha_ignore=dc764bab-77a9-40a6-a734-cc83cc07f11b', {email:email, password:password}, (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({ ...globalState, loggedIn:true, userData:data })
				history.push('/')
			} else {
				// TODO: error handling
			}
		})
	}

	return (
		<form onSubmit={login}>
			<div className={styles.login}>
				<h3 className={styles.heading}>Log in to flowsam<sup>®</sup></h3>
				<div className={styles.inputGroup}>
					<div className={styles.inputLabel}>Email</div>
					<input
						className={styles.inputText}
						name="email"
						type="email"
						spellCheck="false"
						maxLength="100"
						autoComplete="username"
						value={email}
						onChange={e=>setEmail(e.target.value)}
					/>
				</div>
				<div className={styles.inputGroup}>
					<div className={styles.inputLabel}>Password</div>
					<input
						className={styles.inputText}
						name="password"
						type="password"
						spellCheck="false"
						maxLength="100"
						autoComplete="current-password"
						value={password}
						onChange={e=>setPassword(e.target.value)}
					/>
				</div>
				<br />
				<button className="fs-button" tabIndex="-1">LOGIN</button>
			</div>
		</form>
	)
}
