import React, { useState } from 'react'
import styles from './styles.module.css'

// component function
export default function ScoreCard(props) {

	const { type, title, scores, label, image, fullImage } = props
	const barColor = type === 'brand' ? styles.colorBrand : type === 'comms' ? styles.colorComms : styles.colorViewTime
	const [showFullImage, setShowFullImage] = useState(false)

	// dynamic styles
	const bgImageStyle = {
		backgroundImage: 'url(' + image + ')'
	}

	const fullImageStyle = {
		backgroundImage: 'url(' + fullImage + ')'
	}

	const clarityScore = (scores.clarity !== undefined && scores.clarity > -1 ?
		<div className={styles.scoreWrapper}>
			<div className={styles.scoreLabel}>Visual Clarity</div>
			<div className={styles.scoreBox + ' ' + styles.colorClarity}>{scores.clarity}</div>
		</div>
		: scores.clarity === -1 ?
		<div className={styles.scoreWrapper + ' ' + styles.noAsset}>
			<div className={styles.scoreLabel}>n/a</div>
		</div>
		: null
	)

	const brandScore = (scores.brand !== undefined && scores.brand > -1 ?
		<div className={styles.scoreWrapper}>
			<div className={styles.scoreLabel}>Brand Attention</div>
			<div className={styles.scoreBox + ' ' + styles.colorBrand}>{scores.brand}</div>
		</div>
		: scores.brand === -1 ?
		<div className={styles.scoreWrapper + ' ' + styles.noAsset}>
			<div className={styles.scoreLabel}>n/a</div>
		</div>
		: null
	)

	const commsScore = (scores.comms !== undefined && scores.comms > -1 ?
		<div className={styles.scoreWrapper}>
			<div className={styles.scoreLabel}>Comm. Attention</div>
			<div className={styles.scoreBox + ' ' + styles.colorComms}>{scores.comms}</div>
		</div>
		: scores.comms === -1 ?
		<div className={styles.scoreWrapper + ' ' + styles.noAsset}>
			<div className={styles.scoreLabel}>n/a</div>
		</div>
		: null
	)

	const assetLabel = label && <div className={styles.assetLabel}>{label}</div>

	const assetImage = image !== undefined && <div style={bgImageStyle} className={styles.imageWrapper} onClick={e=>setShowFullImage(true)} />
	const fullAssetImage = fullImage !== undefined && showFullImage ? <div className={styles.overlay} onClick={e=>setShowFullImage(false)}><div style={fullImageStyle} className={styles.fullImage} /></div> : null

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.topBar + ' ' + barColor}>{title}</div>
				<div className={styles.contentWrapper}>
					{clarityScore}
					{brandScore}
					{commsScore}
					{assetLabel}
					{assetImage}
				</div>
			</div>
			{fullAssetImage}
		</>
	)
}
