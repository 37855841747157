import React, { useState, useEffect, useRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../../../../utils/globalState'
import { colors } from '../../../../themes/colors'
import styles from './styles.module.css'

// component function
export default function DropZone(props) {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [highlight, setHighlight] = useState()
	const [file, setFile] = useState()
	const [error, setError] = useState()
	const location = useLocation()
	const fileInputRef = useRef()
	const previewRef = useRef()
	const maxMb = globalState.userData?.organization?.subscriptionPlan?.config?.Max_Mb_per_film ? globalState.userData.organization.subscriptionPlan.config.Max_Mb_per_film : 1000

	// check if file object is added in location (comes from dashboard)
	useEffect(() => {
		location.state?.type && validateFile(location.state)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[location.state])

	// selected file thumbnail
	useEffect(() => {
		var videoNode = document.querySelector('video')
		if (file?.type?.indexOf('video/') > -1) {
			previewRef.current.style.backgroundImage = 'none'
			videoNode.src = URL.createObjectURL(file)
			videoNode.currentTime = 1 // start 1 sec in to avoid black start frame
		} else if (file?.type?.indexOf('image/') > -1) {
			previewRef.current.style.backgroundImage = 'url(' + URL.createObjectURL(file) + ')'
			videoNode.src = null
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[file])

	// open file select dialogue
	function openFileDialog() {
		fileInputRef.current.click()
	}

	// validate file when added
	function onFileAdded(e) {
		validateFile(e.target.files[0])
	}

	// handle dropped file
	function onDrop(e) {
		e.preventDefault()
		setHighlight(false)
		validateFile(e.dataTransfer.files[0])
	}

	// handle drag enter
	function onDragOver(e) {
		e.preventDefault()
		setHighlight(true)
	}

	// handle drag leave
	function onDragLeave() {
		setHighlight(false)
	}

	// validate file
	function validateFile(file) {
		if (!file) return
		let err = null
		if (file.size > maxMb * 1000 * 1000) { // check if file size exceeds subscription
			err = 'Sorry, the video size exceeds the ' + maxMb + ' MB per file allowed in your subscription'
			setError(err)
			setFile(null)
			props.fileAdded(null)
		} else if (file.type !== 'video/mp4' && file.type.indexOf('image/') !== 0) { // check file type
			err = 'Sorry, flowsam only likes .mp4 video or image files'
			setError(err)
			setFile(null)
			props.fileAdded(null)
		} else if (file.type.match('image.*')) { // check image dimensions and aspect ratio
			const img = new Image()
			img.src = window.URL.createObjectURL(file)
			img.onload = () => {
				err = (img.width && (img.width/img.height < .5 || img.width/img.height > 2)) ? 'Sorry, flowsam currently only supports image aspect ratios between 0.5 and 2. Your image has an aspect ratio of ' + Math.floor(img.width/img.height*100)/100 + '.' : err
				err = (img.width && (img.width < 160 || img.width > 4096 || img.height < 160 || img.height > 4096)) ? 'Sorry, flowsam currently only supports image resolutions between 160 and 4096' : err
				setError(err ? err : null)
				setFile(err ? null : file)
				props.fileAdded(err ? null : file)
			}
		} else if (file.type.match('video.*')) { // check video dimensions and aspect ratio
			const vid = document.createElement('video')
			vid.src = window.URL.createObjectURL(file)
			vid.addEventListener("loadedmetadata", function removeVideo() {
				vid.removeEventListener("loadedmetadata", removeVideo)
				err = (vid.videoWidth && (vid.videoWidth/vid.videoHeight < .5 || vid.videoWidth/vid.videoHeight > 2)) ? 'Sorry, flowsam currently only supports video aspect ratios between 0.5 and 2. Your video has an aspect ratio of ' + Math.floor(vid.videoWidth/vid.videoHeight*100)/100 + '.' : err
				err = (vid.videoWidth && (vid.videoWidth < 160 || vid.videoWidth > 4096 || vid.videoHeight < 160 || vid.videoHeight > 4096)) ? 'Sorry, flowsam currently only supports video resolutions between 160 and 4096' : err
				vid.src = ''
				vid.load()
				setError(err ? err : null)
				setFile(err ? null : file)
				props.fileAdded(err ? null : file)
			})
		}
	}

	const dropZoneStyle = { backgroundColor: (highlight ? colors.dropZoneHighlight : file ? 'transparent' : colors.background1) }
	const statusStyle = { pointerEvents:'none', color: error && !highlight && colors.error }
	const statusText = highlight ? 'Drop it right here' : error ? error : file ? '' /*file.name*/ : (
		<>
			<div className={styles.icon} />
			<p className={styles.newCta}>Drop video or image here</p>
		</>
	)

	return (
		<>
			<div ref={previewRef} className={styles.preview}>
				<video className={styles.previewVideo} />
			</div>
			<div
				className={styles.uploadField}
				style={dropZoneStyle}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
	    		onDrop={onDrop}
	    		onClick={openFileDialog}>
				<input
					type="file"
					ref={fileInputRef}
					style={{display:'none'}}
					onChange={onFileAdded}
					accept="video/mp4,image/*"
				/>
				<h4 style={statusStyle}>{statusText}</h4>
			</div>
		</>
	)
}
