import React, { useContext, useState, useEffect, useRef } from 'react'
import Alert from '../../common/Alert'
import { APIContext } from '../../../utils/api'
import styles from './styles.module.css'

// component function
export default function AccountInvoices(props) {

	const {org} = props
	const context = useContext(APIContext)
	const [invoices, setInvoices] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// get invoice list
	useEffect(() => {
		context.io.socket.get('/api/v1/subscription/invoice', (data, res) => {
			if (res.statusCode === 200) {
				data.data?.length > 0 && setInvoices(data.data)
			} else {
				showAlert('Error getting invoice list: ' + res.error.message)
			}
		})
	}, [context.io.socket, org])

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	const invoiceList = invoices?.map((inv, i) =>
		<tr key={i}>
			<td className={styles.td}>{new Date(inv.created*1000).toLocaleDateString("en-GB", dateOptions)}</td>
			<td className={styles.td}>{inv.lines.data[0].price.nickname}</td>
			<td className={styles.td}>{inv.charge?.payment_method_details.card.brand + ' •••• ' + inv.charge?.payment_method_details.card.last4}</td>
			<td className={styles.td}>{numberWithCommas(inv.amount_paid/100)} €</td>
			<td className={styles.td}>{inv.number}</td>
			<td className={styles.td}>{inv.status}</td>
			<td style={{textAlign:'right'}}>{inv.invoice_pdf && <a className={styles.downloadLink} rel="noopener noreferrer" href={inv.invoice_pdf}>Download</a>}</td>
		</tr>
	)

	return (invoices ?
		<div className={styles.accountElementBack}>
			<div className="accountElementContainer">
				<h2>Invoices</h2>
				<div className={styles.invoiceBox}>
					<table cellPadding="0" cellSpacing="0" className={styles.invoiceTable}>
						<thead>
							<tr className={styles.label}>
								<td>Date</td>
								<td>Subscription</td>
								<td>Credit card</td>
								<td>Amount</td>
								<td>Invoice No.</td>
								<td>Status</td>
								<td>&nbsp;</td>
							</tr>
						</thead>
						<tbody>
							{invoiceList}
						</tbody>
					</table>
				</div>
			</div>
			{alert}
		</div> : ''
	)
}
