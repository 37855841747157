import React from 'react'
import styles from './styles.module.css'
import DetailsVideo from './DetailsVideo'

// component function
export default function ReportDetails(props) {

	const { video } = props

	return (
		<div className={styles.background}>
			<div className={styles.header}>
				<div className={styles.heading}>
					Ad performance details
					<div className={styles.subHeading}>See how your ad performs during playback</div>
				</div>
			</div>
			<div className={styles.videoWrapper}>
				<DetailsVideo video={video} type='frame' />
			</div>
			<div className={styles.videoWrapper}>
				<DetailsVideo video={video} type='average' />
			</div>
		</div>
	)
}
